.ant-collapse-header {
  display: flex;
  width: 100%;
  align-items: center;
}
.ant-collapse {
  border: none !important;
  margin-bottom: 10px !important;
}
.ant-collapse-item {
  border: none !important;
}
a:hover {
  color: #bcb4ad !important;
}
.ant-pagination-item-a {
  &:hover {
    color: #bcb4ad !important;
  }
  color: #bcb4ad !important;
}
.ant-pagination-item-active a {
  color: #bcb4ad !important;
}
.ant-pagination-item-active {
  border-color: #bcb4ad !important;
  color: #bcb4ad !important;
}

.ant-picker-panel-container {
  transform: scale(1.5);
}
